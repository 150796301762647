import React from "react";
import "./Pricing.scss";
import { Card, Col, Row, Avatar } from "antd";
import icon from "../../Assets/capability-source.png";

export const Pricing = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={16}
        >
          <h1>Pricing</h1>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={16}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "200px",
              height: "fit-content",
              lineHeight: 2,
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                Free tier
              </>
            }
          >
            <p>
              View up to 10 detailed items linked to master items in 1 master
              board
            </p>
            <p>
              <ul>
                <li>Unlimited users</li>
                <li>1 Workspace</li>
                <li>1 Master Board</li>
                <li>10 Linked items</li>
              </ul>
            </p>
            <div>
              Price: <div style={{ fontWeight: "bold" }}>FREE</div>
            </div>
          </Card>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={16}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "200px",
              height: "fit-content",
              lineHeight: 2,
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                Enterprise tier
              </>
            }
          >
            <p>
              View an unlimited number of detailed items linked to master items
              in an unlimited number of master item boards
            </p>
            <p>
              <ul>
                <li>Unlimited users</li>
                <li>Unlimited Workspaces</li>
                <li>Unlimited Master Boards</li>
                <li>Unlimited Linked items</li>
                <li>Assistance with up to 5 linking automations</li>
              </ul>
            </p>
            <div>
              Price:{" "}
              <div style={{ fontWeight: "bold" }}>
                <a href="mailto: info@capabilitysource.com">CONTACT US</a>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
