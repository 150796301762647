/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from "react";
import mondaySdk from "monday-sdk-js";
import "monday-ui-react-core/dist/main.css";
import { filterBoardData } from "../../Util/Monday.util";
import { Loader } from "monday-ui-react-core";
import { Alert } from "antd";
import { getItemById } from "../../Services/Monday.service";
import { Gantt } from "../../Components/Gantt/Gantt";
const monday = mondaySdk();
monday.setApiVersion("2023-10");

export function MasterDetail() {
  const [context, setContext] = useState();
  const [loading, setLoading] = useState(false);
  const [columnExists, setColumnExists] = useState(true);
  const [selectedItemData, setSelectedItemData] = useState();
  const [ganttData, setGanttData] = useState({ items: [] });
  const [error, setError] = useState();
  const [subscription, setSubscription] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isGuest, setIsGuest] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    monday.execute("valueCreatedForUser");
    monday.listen("context", (res) => {
      setContext(res?.data);
      setIsViewOnly(res?.data?.user?.isViewOnly);
      setIsGuest(res?.data?.user?.isGuest);
      // setSubscription(res?.data?.subscription);
    });
  }, []);

  useEffect(() => {
    if (
      context?.workspaceId &&
      ganttData.items.length === 0 &&
      subscription &&
      !loading &&
      !dataFetched
    ) {
      console.log("fetching data...");
      setDataFetched(true);
      setLoading(true);
      getItemById(context.itemId, monday, setSelectedItemData, setError, setLoading);
    }
  }, [context, subscription, loading, dataFetched]);

  useEffect(() => {
    if (selectedItemData && context && ganttData.items.length === 0) {
      //Sadly there isn't a better way to override the context color for the antd collapse component
      document.documentElement.style.setProperty(
        "--textColor",
        context.theme !== "light" ? "white" : "black"
      );
      document.documentElement.style.setProperty(
        "--iconColor",
        context.theme !== "light" ? "white" : "black"
      );

      console.log("Filtering selected item data...");
      filterBoardData(
        setGanttData,
        selectedItemData,
        setLoading,
        setColumnExists,
        monday
      );
    }
  }, [selectedItemData]);

  if (isViewOnly) {
    return (
      <div
        className={`NoContentSpace ${
          context?.theme === "dark"
            ? "dark-theme-text"
            : context?.theme === "black"
            ? "black-theme-text"
            : ""
        }`}
      >
        As a view only member, you are unable to use the Item Relationship Viewer
        Application
      </div>
    );
  }
  if (isGuest) {
    return (
      <div
        className={`NoContentSpace ${
          context?.theme === "dark"
            ? "dark-theme-text"
            : context?.theme === "black"
            ? "black-theme-text"
            : ""
        }`}
      >
        As a guest, you are unable to use the Item Relationship Viewer Application
      </div>
    );
  }
  if (!subscription) {
    return (
      <div className="Paywall">
        <div className="modal-overlay">
          <div className="modal">
            <div className="modal-header">
              <h3>Subscription required!</h3>
            </div>
            <div className="modal-content">
              <p>
                Please select a subscription plan to use the features of the Application
              </p>
            </div>
            <div className="modal-footer">
              <button
                onClick={async () => {
                  await monday.execute("openPlanSelection", {
                    isInPlanSelection: true,
                  });
                }}
                className="btn btn-primary"
              >
                Buy a subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {loading ? (
        <div className="NoContentSpace">
          <Loader color="#292f4c" size={Loader.sizes.LARGE} hasBackground />
          <p style={{ color: context.theme !== "light" ? "white" : "black" }}>
            This can take up to a few minutes...
          </p>
        </div>
      ) : ganttData.items.length > 0 ? (
        <div className={context.theme} style={{ height: "100%", width: "100vw" }}>
          <Gantt data={ganttData} />
        </div>
      ) : !error && !loading ? (
        columnExists ? (
          <div
            style={{ padding: 5 }}
            className={`NoContentSpace ${
              context?.theme === "dark"
                ? "dark-theme-text"
                : context?.theme === "black"
                ? "black-theme-text"
                : ""
            }`}
          >
            <p>There are no connected items for this item</p>
            <p>
              1. Make sure that you have connected this board with another through the
              connect boards column in the connected board
            </p>
            <p>
              2. Make sure that you have set the correct value in the connected boards
              column on the connected board
            </p>
            <p>For a guide on how to use the master detail app, visit this link:</p>
            <a
              href="https://master-detail.capabilitysource.com/HowToUse"
              target="_blank"
              rel="noreferrer"
            >
              How To Use
            </a>
          </div>
        ) : (
          <div
            style={{ padding: 5 }}
            className={`NoContentSpace ${
              context?.theme === "dark"
                ? "dark-theme-text"
                : context?.theme === "black"
                ? "black-theme-text"
                : ""
            }`}
          >
            <p>"LINK - Children" column or "Link - Timesheet" column doesn't exist</p>
          </div>
        )
      ) : (
        <div className="NoContentSpace">
          <Alert
            style={{ width: "80%" }}
            message="Error"
            description="The monday.com API complexity has been exhausted, please refresh this
      tab after 1 minute..."
            type="error"
            showIcon
          />
        </div>
      )}
    </>
  );
}
