import { Card, Col, Row, Avatar, List, Typography, Image } from "antd";
import image1 from "../../Assets/HowToUseScreens/1.png";
import image2 from "../../Assets/HowToUseScreens/2.png";
import image3 from "../../Assets/HowToUseScreens/3.png";
import image4 from "../../Assets/HowToUseScreens/4.png";
import image5 from "../../Assets/HowToUseScreens/5.png";
import image6 from "../../Assets/HowToUseScreens/6.png";
import image7 from "../../Assets/HowToUseScreens/7.png";
import image8 from "../../Assets/HowToUseScreens/8.png";
import image9 from "../../Assets/HowToUseScreens/9.png";
import image10 from "../../Assets/HowToUseScreens/10.png";
import icon from "../../Assets/capability-source.png";
export const HowToUse = () => {
  const images = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
  };
  const data = [
    "Navigate to the Master-Detail Item Card view application in the item from the 1st step",
    "Browse all of your connected items",
    "Sort the items alphabetically for any column of your choosing",
    "Navigate directly to the connected item by clicking on the item’s name in the item name column",
  ];

  const screenshotDescriptions = [
    "If you don't have already, create two boards which you intend to connect in your monday.com workspace.",
    "Preview of data in the first board",
    "Preview of data in the second board",
    "Add a 'Connect boards' column on the board that you wish to connect to your first board.",
    "Click on 'Select boards'",
    "Choose the board that you want to connect to your board and click on the 'Connect boards' button",
    "Now in the 'connected boards' column, choose the item from the other board which should connect to the item in your current board.",
    "Tick the checkbox with the item that you want to connect.",
    "Now on your first board which you connected to the other board, open the item page of the item that you connected.",
    "Add the Master Detail Application to it if it is missing and enjoy your master detail view.",
  ];
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={10}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "450px",
              height: "fit-content",
              lineHeight: 2,
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                About ℹ️
              </>
            }
          >
            Master-Detail Application is an intuitive and user-friendly
            master-detail view that empowers the users to easily navigate and
            access their connected items through the connect boards column in
            their monday.com | A new way of working instance. This innovative
            tool offers a convenient and streamlined approach to managing
            connected items, enhancing the user's productivity and efficiency.
            <p></p>
            <b>Note:</b> The Guest user and Viewer user in monday.com are not
            able to use the app.
          </Card>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={10}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "450px",
              height: "fit-content",
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                How to 💡
              </>
            }
          >
            <List
              dataSource={data}
              renderItem={(item, i) => (
                <List.Item>
                  <Typography.Text>{i + 1}.</Typography.Text> {item}
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={10}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "450px",
              height: "fit-content",
              lineHeight: 2,
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                Getting started 🚀
              </>
            }
          >
            Welcome to our tutorial on setting up the Master Detail Application
            in your workspace. In this tutorial, we will be guiding you through
            the setup of the instance and the possibilities that the
            master-detail Application offers you. To make the process easier, we
            have created a video that will walk you through each step of the
            wizard. To get started, simply follow the instructions in the video
            and you'll have your view set up in no time! So, please make sure to
            watch the video carefully. If you have any questions or encounter
            any issues during the process, please don't hesitate to reach out to
            our support team for assistance.
          </Card>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          xs={24}
          xl={10}
        >
          <Card
            style={{
              width: 500,
              margin: 15,
              minHeight: "450px",
              height: "fit-content",
            }}
            title={
              <>
                <Avatar size={"large"} style={{ marginRight: 15 }} src={icon} />
                Contact us 📱
              </>
            }
          >
            <div style={{ lineHeight: 2 }}>
              CapabilitySource develops digital marketing strategies and
              improves marketing operations capabilities that support the
              transformation journey and improve overall marketing maturity.
            </div>
            <div style={{ marginTop: "22%" }}>
              <p style={{ fontWeight: "bold" }}>Contact Us</p>
              <p>CapabilitySource</p>
              <p>info@capabilitysource.com</p>
              <p>https://www.capabilitysource.com</p>
            </div>
          </Card>
        </Col>
        {[...new Array(10)].map((item, i) => {
          return (
            <>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                xs={24}
                xl={10}
              >
                <Card
                  key={i + 1}
                  style={{
                    width: 500,
                    margin: 15,
                    minHeight: "550px",
                    lineHeight: 2,
                    textAlign: "center",
                  }}
                  title={`Step ${i + 1}`}
                >
                  <Image src={images[`image${i + 1}`]} />
                  <div
                    style={{ padding: 15, marginTop: 15, fontWeight: "bold" }}
                  >
                    {screenshotDescriptions[i]}
                  </div>
                </Card>
              </Col>
            </>
          );
        })}
      </Row>
    </div>
  );
};
