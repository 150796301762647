import React from "react"
import { createRoot } from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { registerLicense } from "@syncfusion/ej2-base"

// Registering Syncfusion license key
// todo: get valid token that isnt trial
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF1cXGNCd0x0RXxbf1x0ZFFMZVVbRXdPMyBoS35RckVrW35edXRVRGBZUkBw"
)

const root = createRoot(document.getElementById("root"))
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)
