import React from "react";
import "./App.css";
import { MasterDetail } from "./Screens/MasterDetail/MasterDetail";
import { PrivacyPolicy } from "./Screens/PrivacyPolicy/PrivacyPolicy";
import { TermsOfService } from "./Screens/TermsOfService/TermsOfService";
import { Pricing } from "./Screens/Pricing/Pricing";
import { HowToUse } from "./Screens/HowToUse/HowToUse";
import { Route, Routes } from "react-router-dom";

const App = () => {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/*" element={<MasterDetail />} />
        <Route exact path="/TermsOfService" element={<TermsOfService />} />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route exact path="/HowToUse" element={<HowToUse />} />
        <Route exact path="/Pricing" element={<Pricing />} />
      </Routes>
    </div>
  );
};

export default App;
